<template>

    <div>
        <v-container>
            <div class="">
                <div class="d-flex justify-center align-center">
                    <v-icon size="6rem" color="green">mdi-check-circle</v-icon>
                </div>
                <div class="text-center mt-3">
                    <h3>Your subscription has been successfully processed. </h3>
                    <p>You will be redirected to the page of your active plan in <strong> {{ redirectionSeconde
                            }}</strong>
                        seconds. You will be able to download your receipt on that page.</p>
                </div>
            </div>
        </v-container>
    </div>

</template>

<script>

import { mapActions } from "vuex";


export default {

    data() {
        return {
            loaded: false,
            redirectionSeconde:5
        }
    },
    methods: {
    ...mapActions([
      "getActiveSubscription",
    ]),
    },
    mounted() {
        this.redirectionSeconde = 5
        this.redirectionInterval = setInterval(async () => {
            this.redirectionSeconde--
            if (this.redirectionSeconde < 1) {
                clearInterval(this.redirectionInterval)
                await this.getActiveSubscription()
                this.$router.push('/active-subscription')
            }
        }, 1000)
    }
}
</script>